<template>
<div class="erfassen card" v-on:keydown.enter.prevent="onEnterKeyDown">
  <div class="card-content">
    <div class="row">
      <div class="col s12">
        <span class="card-title">Teilnahme als Sponsor</span>
      </div>
      <div class="col s12">
        <p>Bitte teilen Sie uns mit in welcher Form Sie den Adventskalender als Sponsor unterstützen
        möchten.</p>
      </div>
        <div class="row">
          <div class="col s12">
            <h6>Warengutscheine</h6>
            <p>Hier können Sie einen Warengutschein aus Ihrer Firma anbieten. Der Mindeswert Ihres
              Sponsorings muss 220€ betragen, kann aber auf Gutscheine mit einem Mindestwert von 40€
              aufgeteilt werden und gilt auch in Kombination mit einer Sachspende.
              HINWEIS: Es wird ein Lions Gutschein für alle Gewinne ausgestellt, dieser muss in
              Ihrem Geschäft akzeptiert werden.
            </p>
          </div>
        </div>
        <div class="row valign-wrapper">
          <div class="input-field col s2">
            <input id="anzahlGutscheine1" type="number" min="0" placeholder="Anzahl"
                   v-model.number="gutschein1.menge" data-index="1">
            <label for="anzahlGutscheine1" class="active">Anzahl</label>
          </div>
          <div class="col s1">
            <p class="center-align">x</p>
          </div>
          <div class="input-field col s9">
            <input id="wertGutscheine1" type="number" min="40" placeholder="Gutscheinwert"
                   v-model.number="gutschein1.wert" data-index="2" class="validate">
            <label for="wertGutscheine1" class="active">Gutscheinwert in €</label>
            <span class="helper-text" data-error="Der Mindestwert für einen Gutschein beträgt 40€">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s6">
            <input id="gutschein1Abholort" type="text" placeholder="Abholort"
                   v-model="gutschein1.abholort" data-index="3">
            <label for="gutschein1Abholort" class="active">Abholort</label>
          </div>
          <div class="input-field col s6">
            <input id="gutschein1Abholzeit" type="text" placeholder="Abholzeit"
                   v-model="gutschein1.abholzeit">
            <label for="gutschein1Abholzeit" class="active">Abholzeit</label>
          </div>
        </div>
        <div class="row valign-wrapper">
          <div class="input-field col s2">
            <input id="anzahlGutscheine2" type="number" min="0" placeholder="Anzahl"
                   v-model.number="gutschein2.menge" data-index="4">
            <label for="anzahlGutscheine2" class="active">Anzahl</label>
          </div>
          <div class="col s1">
            <p class="center-align">x</p>
          </div>
          <div class="input-field col s9">
            <input id="wertGutscheine2" type="number" min="40" placeholder="Gutscheinwert"
                   v-model.number="gutschein2.wert" data-index="5" class="validate">
            <label for="wertGutscheine2" class="active">Gutscheinwert in €</label>
            <span class="helper-text" data-error="Der Mindestwert für einen Gutschein beträgt 40€">
            </span>
          </div>
        </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="gutschein2Abholort" type="text" placeholder="Abholort"
                 v-model="gutschein2.abholort" data-index="6">
          <label for="gutschein2Abholort" class="active">Abholort</label>
        </div>
        <div class="input-field col s6">
          <input id="gutschein2Abholzeit" type="text" placeholder="Abholzeit"
                 v-model="gutschein2.abholzeit" data-index="7">
          <label for="gutschein2Abholzeit" class="active">Abholzeit</label>
        </div>
      </div>
        <div class="row">
          <div class="col s12">
            <h6>Sachspende</h6>
            <p>Bitte geben Sie die Anzahl der Sachspenden und den Wert je Sachspende an.
            Der Mindestwert Ihres Sponsoring muss 220€ betragen, kann aber auf Sachspenden mit einem
              Mindestwert von 40€ aufgeteilt werden und gilt auch in Kombination mit einem
              Warengutschein. HINWEIS: Es wird ein Lions Gutschein für
              alle Gewinne ausgestellt, dieser muss in Ihrem Geschäft akzeptiert werden.</p>
          </div>
        </div>
        <div class="row valign-wrapper">
          <div class="input-field col s2">
            <input id="anzahlSachpreise1" type="number" min="0" placeholder="Anzahl"
                   v-model.number="sachspenden[0].menge" data-index="8" class="validate">
            <label for="anzahlSachpreise1" class="active">Anzahl</label>
            <span class="helper-text" data-error="Der Mindestwert für einen Sachpreis beträgt 40€">
            </span>
          </div>
          <div class="col s1">
            <p class="center-align">x</p>
          </div>
          <div class="input-field col s9">
            <input id="wertSachpreis1" type="number" min="40" placeholder="Sachpreiswert"
                   v-model.number="sachspenden[0].wert" data-index="9" class="validate">
            <label for="wertSachpreis1" class="active">Sachpreiswert in €</label>
            <span class="helper-text" data-error="Der Mindestwert für einen Sachpreis beträgt 40€">
            </span>
          </div>
        </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="sachpreis1Abholort" type="text" placeholder="Abholort"
                 v-model="sachspenden[0].abholort" data-index="10">
          <label for="sachpreis1Abholort" class="active">Abholort</label>
        </div>
        <div class="input-field col s6">
          <input id="sachpreis1Abholzeit" type="text" placeholder="Abholzeit"
                 v-model="sachspenden[0].abholzeit" data-index="11">
          <label for="sachpreis1Abholzeit" class="active">Abholzeit</label>
        </div>
      </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="bezeichnungSachpreis1" type="text" placeholder="Sachpreisbezeichnung"
                   v-model="sachspenden[0].bezeichnung" data-index="12">
            <label for="bezeichnungSachpreis1" class="active">Bezeichnung</label>
          </div>
        </div>
        <div class="row valign-wrapper"> <!--Sachpreis2 -->
          <div class="input-field col s2">
            <input id="anzahlSachpreise2" type="number" min="0" placeholder="Anzahl"
                   v-model.number="sachspenden[1].menge" data-index="8">
            <label for="anzahlSachpreise2" class="active">Anzahl</label>
          </div>
          <div class="col s1">
            <p class="center-align">x</p>
          </div>
          <div class="input-field col s9">
            <input id="wertSachpreis2" type="number" min="40" placeholder="Sachpreiswert"
                   v-model.number="sachspenden[1].wert" data-index="9">
            <label for="wertSachpreis2" class="active">Sachpreiswert in €</label>
          </div>
        </div>
      <div class="row">
        <div class="input-field col s6">
          <input id="sachpreis2Abholort" type="text" placeholder="Abholort"
                 v-model="sachspenden[1].abholort" data-index="11">
          <label for="sachpreis2Abholort" class="active">Abholort</label>
        </div>
        <div class="input-field col s6">
          <input id="sachpreis2Abholzeit" type="text" placeholder="Abholzeit"
                 v-model="sachspenden[1].abholzeit" data-index="12">
          <label for="sachpreis2Abholzeit" class="active">Abholzeit</label>
        </div>
      </div>
      <div class="row">
          <div class="input-field col s12">
            <input id="bezeichnungSachpreis2" type="text" placeholder="Sachpreisbezeichnung"
                   v-model="sachspenden[1].bezeichnung" data-index="10">
            <label for="bezeichnungSachpreis2" class="active">Bezeichnung</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <h6>Barspende</h6>
            <p>Mit den Barspenden finanziert der Lions Club den Kauf von besonderen Gewinnen
            (z.B. Hauptgewinn) und alle weiteren Kosten rund um den Kalender (z.B. Druckkosten).
            Ein Teil der Barspenden fließt auch an die Bedürftigen. Ab einer Barspende von 500€ sind
            Sie ein PREMIUM Sponsor und Ihr Logo wird auf der Rückseite des Kalenders deutlich
              größer dargestellt. <strong>Die Höhe der Barspende muss mindestens 100€ betragen</strong>.</p>
          </div>
          <div class="input-field col s12">
            <input id="wertBarspende" type="number" min="100" step="10" placeholder="Barspendenwert"
            v-model.number="barspende" data-index="13" class="validate">
            <label for="wertBarspende" class="active"></label>
            <span class="helper-text" data-error="Der Mindestwert für die Barspende beträgt 100€">
            </span>
          </div>
<!--
          <div class="col s12 input-field">
            <p>
              <label>
                <input type="checkbox" v-model="keinSponsoring" @change="keinSponsoringChanged" />
                <span>Ich möchte dieses Jahr kein Sponsoring für den Adventskalender abgeben, aber
                  den Lions-Club durch meine Bestellung von Kalendern unterstützen
                  (*Die gewünschte Kalenderanzahl können Sie nach dem Speichern eingeben*)</span>
              </label>
            </p>
          </div>
-->
          <div class="col s12">
            <p>Ich habe zur Kenntnis genommen und bin damit einverstanden, dass das Lions Hilfswerk
              Emmerich e.V. einen selbst gestalteten Gutschein für meinen
              gesponserten Gewinn an den Gewinner ausgibt. Dieser wird i. d. R. mit einer
              3-monatigen Frist (31.03 des Folgejahres) zur Einlösung des Gutscheins versehen. Der
              Gewinner kann den Gewinn ausschließlich mit diesem Lions-Gutschein einlösen.
              Nach Ablauf der Einlösungs-/Verfallsfrist ist das Lions Hilfswerk Emmerich e.V.
              berechtigt, diesen auf einen Dritten zu übertragen, sofern die Übertragung
              gemeinnützig und satzungskonform ist. Eine Spendenbescheinigung kann ausgestellt
              werden.</p>
          </div>
          <div class="col s12">
            <p>
              <label>
                <input type="checkbox" v-model="acceptsTerms" />
                <span>Ja<span class="red-text">*</span></span>
              </label>
            </p>
          </div>
        </div>
      <div v-if="!sachspendenHaveNames" class="input-field col s12">
        <span class="red-text">Bitte geben Sie eine Sachspendenbezeichnung an</span>
      </div>
      <div class="col s12">
        <button type="button" class="btn blue darken-1" style="width: 100%"
        :class="{disabled: !canSave}" @click="erfasseSpenden">Speichern</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import M from 'materialize-css';

export default {
  name: 'Erfassen',
  data() {
    return {
      kontonummer: '',
      keinSponsoring: false,
      gutschein1: {
        menge: undefined,
        wert: undefined,
        abholort: undefined,
        abholzeit: undefined,
      },
      gutschein2: {
        menge: undefined,
        wert: undefined,
        abholort: undefined,
        abholzeit: undefined,
      },
      sachspenden: [
        {
          menge: undefined,
          wert: undefined,
          bezeichnung: undefined,
          abholort: undefined,
          abholzeit: undefined,
        },
        {
          menge: undefined,
          wert: undefined,
          bezeichnung: undefined,
          abholort: undefined,
          abholzeit: undefined,
        },
      ],
      barspende: undefined,
      acceptsTerms: false,
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
    });
    M.AutoInit();
    this.kontonummer = this.$route.query.konto;
  },
  methods: {
    erfasseSpenden() {
      if (this.barspende > 0) {
        this.$store.dispatch('updateBarspende', this.barspende);
      }

      if (this.gutscheinWert > 0) {
        const gutscheine = [];

        if (this.gutschein1.menge && this.gutschein1.wert) {
          gutscheine.push(this.gutschein1);
        }

        if (this.gutschein2.menge && this.gutschein2.wert) {
          gutscheine.push(this.gutschein2);
        }

        if (gutscheine.length > 0) {
          this.$store.dispatch('updateGutscheine', gutscheine);
        }
      }

      if (this.sachspendenWert > 0) {
        const sachspenden = this.sachspenden
          .filter((spende) => spende.wert && spende.bezeichnung && spende.menge);

        if (sachspenden.length > 0) {
          this.$store.dispatch('updateSachspenden', sachspenden);
        }
      }

      this.$router.push(`/bestellen?konto=${this.kontonummer}`);
    },

    onEnterKeyDown($event) {
      const nextDataIndex = Number.parseInt($event.target.getAttribute('data-index'), 10);

      if (Number.isNaN(nextDataIndex)) {
        return;
      }

      const nextInput = document.querySelectorAll('[data-index]')[nextDataIndex];

      if (!nextInput) {
        return;
      }

      nextInput.focus();
    },
  },
  computed: {
    gesamtWert() {
      return this.gutscheinWert + this.sachspendenWert;
    },
    gutscheinWert() {
      let wert = 0;

      if (this.gutschein1.menge && this.gutschein1.wert) {
        wert += this.gutschein1.menge * this.gutschein1.wert;
      }

      if (this.gutschein2.menge && this.gutschein2.wert) {
        wert += this.gutschein2.menge * this.gutschein2.wert;
      }

      return wert;
    },
    sachspendenWert() {
      let wert = 0;

      this.sachspenden.filter((spende) => spende.wert && spende.bezeichnung && spende.menge)
        .forEach((spende) => {
          wert += spende.menge * spende.wert;
        });

      return wert;
    },
    canSave() {
      if (!this.acceptsTerms) {
        return false;
      }

      if (this.gutschein1.wert && this.gutschein1.wert < 40) {
        return false;
      }
      if (this.gutschein2.wert && this.gutschein2.wert < 40) {
        return false;
      }
      if (this.sachspenden[0].wert && this.sachspenden[0].wert < 40) {
        return false;
      }
      if (this.sachspenden[1].wert && this.sachspenden[1].wert < 40) {
        return false;
      }

      const sachwert = this.gesamtWert;
      let barwert = parseFloat(this.barspende);
      barwert = Number.isNaN(barwert) ? 0 : barwert;

      return this.sachspendenHaveNames && (sachwert >= 220 || barwert >= 100);
    },
    sachspendenHaveNames() {
      let haveNames = true;

      if (this.sachspenden[0].menge && this.sachspenden[0].wert) {
        haveNames = this.sachspenden[0].bezeichnung !== undefined
          && this.sachspenden[0].bezeichnung !== ''
          && haveNames;
      }

      if (this.sachspenden[1].menge && this.sachspenden[1].wert) {
        haveNames = this.sachspenden[1].bezeichnung !== undefined
          && this.sachspenden[1].bezeichnung !== ''
          && haveNames;
      }

      return haveNames;
    },
  },
};
</script>

<style scoped>

</style>
